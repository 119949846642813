<template>
    <div class="layer">
        <div class="container-pc">
            <div class="add-view">
                <div class="add-view-title">
                    <div>Shipping Address</div>
                    <div class="iconfont icon-guanbi1 CURSOR" @click="handleOnChangeAddress('0')"></div>
                </div>
                <div class="add-view-info">
                    <div class="myinfo-info-info">
                        <div class="myinfo-item-item">
                            <div class="myinfo-item-label">*Country or region：</div>
                            <el-select v-model="confirmAddressData.country" placeholder="Country or region"
                                style="width: 350px;">
                                <el-option v-for=" item in CountryOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="myinfo-item-item">
                            <div class="myinfo-item-label">*Name：</div>
                            <el-input v-model="confirmAddressData.receiver" class="login-input" clearable
                                placeholder="Name" />
                        </div>
                        <div class="myinfo-item-item">
                            <div class="myinfo-item-label">*Phone number：</div>
                            <el-input placeholder="Phone number" v-model="confirmAddressData.mobile" class="login-value"
                                style="width: 350px;" clearable>
                                <template #prepend>
                                    <el-select v-model="countryItem" placeholder="Area code" style="width: 150px;"
                                        @change="handleOnChangeArea">
                                        <el-option :label="countryItem.label" :value="countryItem.value"
                                            v-for="(countryItem, index) in countryData"></el-option>
                                    </el-select>
                                </template>
                            </el-input>
                        </div>
                        <div class="myinfo-item-item">
                            <div class="myinfo-item-label">*Address：</div>
                            <el-input v-model="confirmAddressData.street" class="login-input" clearable
                                placeholder="Street address or P.0.Box" />
                        </div>
                        <div class="myinfo-item-item">
                            <div class="myinfo-item-label"></div>
                            <el-input v-model="confirmAddressData.detail" class="login-input" clearable
                                placeholder="Apt, suite, unit, building, floor, etc." />
                        </div>
                        <div class="myinfo-item-item">
                            <div class="myinfo-item-label">*City：</div>
                            <el-input v-model="confirmAddressData.city" class="login-input" clearable
                                placeholder="City" />
                        </div>

                        <div class="myinfo-item-item">
                            <div class="myinfo-item-label">*State：</div>
                            <el-select v-model="confirmAddressData.state" placeholder="State" style="width: 350px;">
                                <el-option v-for="item in USA" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>

                        <div class="myinfo-item-item">
                            <div class="myinfo-item-label">*ZIP Code：</div>
                            <el-input v-model="confirmAddressData.postcode" class="login-input" clearable
                                placeholder="ZIP Code" />
                        </div>

                    </div>
                    <van-button type="primary" size="small" color="#414141" style="width: 200px;" :loading="isLoading"
                        @click="handleConfirmAddress">Confirm</van-button>

                </div>
            </div>
        </div>

        <div class="container-m">
            <div class="add-view">
                <div class="add-view-title">
                    <div>shipping address</div>
                    <div class="iconfont icon-guanbi1 CURSOR" @click="handleOnChangeAddress('0')"></div>
                </div>
                <div class="login-m-view">
                    <div class="login-m-input-item">
                        <div class="login-label">Country or region：</div>
                        <input type="text" class="login-value" placeholder="Country or region"
                            @click="handleShowCountry('isShowCountry')" v-model="confirmAddressData.country">
                    </div>

                    <div class="login-m-input-item">
                        <div class="login-label">Name：</div>
                        <input type="text" v-model="confirmAddressData.receiver" class="login-value" placeholder="Name">
                    </div>
                    <div class="login-m-input-item">
                        <div class="login-label">Phone number：</div>
                        <input type="text" v-model="confirmAddressData.mobile" class="login-value"
                            placeholder="Phone number">
                    </div>

                    <div class="login-m-input-item">
                        <div class="login-label">detail：</div>
                        <input type="text" v-model="confirmAddressData.detail" class="login-value" placeholder="detail">
                    </div>

                    <div class="login-m-input-item">
                        <div class="login-label">Address：</div>
                        <input type="text" v-model="confirmAddressData.street" class="login-value"
                            placeholder="Address">
                    </div>
                    <div class="login-m-input-item">
                        <div class="login-label">city：</div>
                        <input type="text" v-model="confirmAddressData.city" class="login-value" placeholder="city">
                    </div>


                    <div class="login-m-input-item">
                        <div class="login-label">state：</div>
                        <input type="text" class="login-value" placeholder="Country or region"
                            @click="handleShowCountry('isShowState')" v-model="confirmAddressData.state">
                    </div>

                    <div class="login-m-input-item">
                        <div class="login-label">ZIP Code：</div>
                        <input type="text" v-model="confirmAddressData.postcode" class="login-value"
                            placeholder="ZIP Code">
                    </div>

                    <van-button type="primary" size="small" color="#414141" style="width: 200px;" :loading="isLoading"
                        @click="handleConfirmAddress">Confirm</van-button>

                </div>
            </div>
        </div>
    </div>

    <van-popup v-model:show="isShowCountry" round position="bottom" :style="{ height: '50%' }">
        <van-picker title="Country or region" :columns="CountryOptions"
            @confirm="handleOnCountryConfirm($event, 'isShowCountry')" @cancel="handleShowCountry('isShowCountry')"
            :columns-field-names="{ text: 'label' }" confirm-button-text="Confirm" cancel-button-text="Cancel" />
    </van-popup>
    <van-popup v-model:show="isShowState" round position="bottom" :style="{ height: '50%' }">
        <van-picker title="Country or region" :columns="USA" @confirm="handleOnCountryConfirm($event, 'isShowState')"
            @cancel="handleShowCountry('isShowState')" :columns-field-names="{ text: 'label' }"
            confirm-button-text="Confirm" cancel-button-text="Cancel" />
    </van-popup>
</template>



<script name="OrderAddress" setup>
import countryData from "@/utils/countryData";
import { ref, defineProps, defineEmits, onMounted } from 'vue'
import requestAsync from '@/utils/request';
import { ElMessage } from 'element-plus'
import USA from '@/utils/GeoJson/USA.json'

const isShowCountry = ref(false) // 选择国家
const isShowState = ref(false) // 选择州
const countryItem = ref('')
const CountryOptions = ref([{
    value: 'USA',
    label: 'US'
}])
const props = defineProps({
    info: Object
})

const emit = defineEmits(['change']);
const isLoading = ref(false)

const confirmAddressData = ref({
    id: '',
    country: '', // 国家
    receiver: "", // 姓名
    mobile: "", // 手机号
    street: "", // 街道信息
    detail: '',  // 门牌号
    city: "", // 市
    state: "", // 州
    postcode: '', // 邮编
})

onMounted(() => {
    confirmAddressData.value.id = props.info.id || ''
    confirmAddressData.value.country = props.info.country || ''
    confirmAddressData.value.receiver = props.info.receiver || ''
    confirmAddressData.value.mobile = props.info.mobile || ''
    confirmAddressData.value.street = props.info.street || ''
    confirmAddressData.value.detail = props.info.detail || ''
    confirmAddressData.value.city = props.info.city || ''
    confirmAddressData.value.state = props.info.state || ''
    confirmAddressData.value.postcode = props.info.postcode || ''
})






const handleConfirmAddress = async () => {
    if (!confirmAddressData.value.country ||
        !confirmAddressData.value.receiver ||
        !confirmAddressData.value.mobile ||
        !confirmAddressData.value.street ||
        !confirmAddressData.value.city ||
        !confirmAddressData.value.state ||
        !confirmAddressData.value.postcode
    ) {
        ElMessage.error('Please complete your form')
        return
    }
    if (isLoading.value) return
    isLoading.value = true
    if (!(confirmAddressData.value.mobile.includes('+'))) {
        if (countryItem.value) {
            confirmAddressData.value.mobile = '+' + countryItem.value + confirmAddressData.value.mobile
        }
    }

    const data = await requestAsync({
        url: '/userAddressinfo/userAddressinfoSaveOrUpdate',
        methods: 'post',
        data: confirmAddressData.value
    })
    if (data.code == 200) {
        ElMessage({
            showClose: true,
            message: data.msg,
            type: 'success',
        })
        handleOnChangeAddress('1')
    } else {
        ElMessage.error(data.msg)
    }
    isLoading.value = false
}


const handleOnChangeAddress = (type) => {
    /* 

        1 刷新列表
        0 不刷新
    
    */
    emit('change', type)
}

const handleOnCountryConfirm = ($event, type) => {
    if (type == 'isShowCountry') {
        confirmAddressData.value.country = $event.selectedOptions[0].label
        isShowCountry.value = !isShowCountry.value
    }
    if (type == 'isShowState') {
        confirmAddressData.value.state = $event.selectedOptions[0].label
        isShowState.value = !isShowState.value
    }
}

const handleShowCountry = (type) => {
    if (type == 'isShowCountry') {
        isShowCountry.value = !isShowCountry.value
    }
    if (type == 'isShowState') {
        isShowState.value = !isShowState.value
    }
}

const handleOnChangeArea = () => {
    confirmAddressData.value.mobile = ''
}


</script>



<style lang="scss" scoped>
.layer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 999;
}

.container-pc {
    .add-view {
        width: 700px;
        min-height: 320px;
        position: absolute;
        left: 50%;
        top: 170px;
        margin-left: -350px;
        font-size: 14px;
        background-color: #fff;
        padding-bottom: 20px;

        .add-view-title {
            display: flex;
            justify-content: space-between;
            position: relative;
            height: 32px;
            line-height: 32px;
            background-color: #f1f1f1;
            padding: 0 15px;
            font-size: 14px;
            font-weight: 700;
        }

        .add-view-info {
            padding: 0 30px;
        }

        .myinfo-info-info {
            padding: 40px 0 20px 0;
            width: 100%;

            .myinfo-info-title {
                width: 100%;
                height: 40px;
                line-height: 40px;
                border-bottom: 1px solid #f5f6f7;
                margin: 20px 0;
            }

            .myinfo-item-item {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .myinfo-item-label {
                    width: 200px;
                    font-size: 15px;
                }

                .login-input {
                    width: 350px;
                }
            }
        }
    }
}

.container-m {
    .add-view {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        font-size: .25rem;
        background-color: #fff;
        padding: .2rem;

        .add-view-title {
            display: flex;
            justify-content: space-between;
            position: relative;
            height: .3rem;
            line-height: .3rem;
            padding: 0 0.15rem;
            font-weight: 700;
        }
    }

    .login-m-view {
        width: 100%;
        margin: 0 auto;
        padding: 0 .3rem;

        .login-m-title {
            width: 100%;
            height: .5rem;
            line-height: .5rem;
            text-align: center;
            font-size: .3rem;
        }

        .login-m-tips {
            font-size: .25rem;
            margin: .2rem 0;
            text-align: center;
        }

        .login-m-state {
            display: flex;
            align-items: center;
            width: 100%;
            height: 1rem;
            font-size: .3rem;

            div {
                margin-right: .2rem;
            }
        }

        .login-m-input-item {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: .8rem;
            margin: .2rem auto;
            border-bottom: 1px solid #f1f1f1;

            .login-label {
                width: 3rem;
                line-height: .8rem;
                text-align: center;
                font-size: .25rem;
            }

            .login-value {
                width: 4rem;
                font-size: .25rem;
                padding-left: .3rem;
                border: none;
            }
        }

        .login-button {
            margin: .4rem 0;
        }
    }
}
</style>